<page-layout>
    <div class="col-md-12 f-container f-wrap-wrap f-stack-vertical-mobile justify-content-space-around">

        <dashboard-card class="f-column-30 f-column-100-mobile"
                        *ngIf="currentUserIsAdmin"
                        title="dashboard.manage_users"
                        description="dashboard.manage_users_description"
                        button-text="general.manage"
                        link="/users"
                        icon="fa-users">
		</dashboard-card>

    </div>
</page-layout>