<form-page title="{{'general.create' | translate}} {{'users.user' | translate}}"
           [errors]="errors"
           [successes]="successes"
           [save]="save.bind(this)"
           icon="fa-plus">

    <strong>{{errorMessage}}</strong>

    <label-input-pair label="users.username"
                      required
                      icon="<i class='fa fa-user'></i>"
                      [(model)]="user.username"></label-input-pair>

    <label-input-pair type="email" label="users.email"
                      required
                      type="email"
                      icon="<i class='fa fa-envelope'></i>"
                      [(model)]="user.email"></label-input-pair>

    <label-input-pair label="users.firstname"
                      icon="<i class='fa fa-font'></i>"
                      required
                      [(model)]="user.firstName"></label-input-pair>

    <label-input-pair label="users.lastname"
                      icon="<i class='fa fa-font'></i>"
                      required
                      [(model)]="user.lastName"></label-input-pair>

    <label-input-pair label="users.address"
                      required
                      icon="<i class='fa fa-home'></i>"
                      [(model)]="user.address"></label-input-pair>

    <label-input-pair label="users.phone"
                      required
                      icon="<i class='fa fa-phone'></i>"
                      [(model)]="user.phone"></label-input-pair>

</form-page>