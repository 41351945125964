<section class="top-header" id="header">
	<header class="clearfix">
		<!--<a  href="" class="toggle-min" (click)="false">-->
			<!--<i class="fa fa-bars"></i>-->
		<!--</a>-->
		<div class="logo">
			<a routerLink="/dashboard">
				<img src="./assets/gfx/logo.png"/>
			</a>
		</div>
		<div class="top-nav">
			<ul class="nav-left list-unstyled hidden-xs">
				<!-- <li class="dropdown" dropdown>
					<a style="font-weight: 400" href="{{partALink}}">{{ 'Eregistrations' | translate }}</a>
				</li> -->
			</ul>
			<ul class="nav-right pull-right list-unstyled">
				<li *ngIf="serviceId != undefined">					
					<a routerLink="/services/{{serviceId}}/preview2">
						<i class="fa fa-eye"></i>
						<span>{{ 'header.preview' | translate }}</span>
					</a>
				</li>
				<li class="dropdown nav-profile" dropdown style="padding-top: 4px;">
					<span dropdownToggle class="dropdown-toggle cursor-pointer color-white-on-desktop-and-black-on-mobile">
						{{translate.currentLang | translate}} <span class="caret color-white-on-desktop-and-black-on-mobile"></span>
					</span>
					<ul *dropdownMenu class="dropdown-menu">
						<li  role="menuitem" *ngFor="let lang of translate.getLangs()" >
							<a href="#" (click)="translate.use(lang); false;">{{ lang | translate }}</a>
						</li>

					</ul>
				</li>
				<li class="dropdown nav-profile" dropdown style="padding-top: 2px;">
					<span dropdownToggle class="dropdown-toggle cursor-pointer color-white-on-desktop-and-black-on-mobile hide-320">
						<i class="fa fa-user"></i>
							<span>{{getName()}}</span>
						<span style="margin-left:5px;padding-bottom: 2px"
							  *ngIf="showNotVerifiedMessage"
							  class="c-badge c-badge--error hidden-xs">
							{{ verificationMessage | translate }}
						</span>
					</span>
					<ul class="dropdown-menu" *dropdownMenu>
						<li role="menuitem">
							<a (click)="onLogout();">
								<i class="fa fa-sign-out"></i>
								<span>{{ 'header.log_out' | translate }}</span>
							</a>
						</li>
					</ul>
				</li>
				<li style="cursor: pointer" class="dropdown text-normal nav-profile pull-right">
					<a (click)="onLogout();"><i class="fa fa-sign-in"></i></a>
				</li>
			</ul>
		</div>
	</header>
</section>
