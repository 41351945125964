<large-page-layout title="{{'dashboard.manage_users' | translate}}" icon="fa-users" goBackLink="/dashboard">

	<div class="list-nav">
		<input style="max-width: 300px; padding-left: 10px; min-width: 200px; margin-left: 10px;"
			[attr.value]="params.searchTerm"
			(input)="setParam('searchTerm', $event.target.value);"
			class="form-control"
			placeholder="{{ 'Type to search' | translate }}"
			/>
		<button style="min-width: fit-content;" class="btn btn-w-md btn-line-success" (click)="setupBindToCasModalDefault();bindToCASModal.show();">
			<i class="fa fa-plus"></i>&nbsp;
			<span>{{'users.bind_to_cas' | translate}}</span>
		</button>
		<div *ngIf="totalResultItemsCount > params.itemsOnPage">
			<ul style="list-style: none;">
				<li *ngFor="let item of pages | paginate: { itemsPerPage: params.itemsOnPage, currentPage: params.nrPage, totalItems: totalResultItemsCount }"></li>
			</ul>
			<pagination-controls class="app-pagination"
				previousLabel="{{ 'general.previous_page' | translate }}"
				nextLabel="{{ 'general.next_page' | translate }}"
				(pageChange)="setParam('nrPage', $event)"></pagination-controls>
		</div>
	</div>

    <table class="table table-hover">
        <thead>
			<th *ngFor="let prop of propsToReturn">{{ prop.label }}</th>
			<th></th>
		</thead>
		<tbody *ngIf="currentState==='loading'">
			<tr>
				<td [attr.colspan]="propsToReturn.length" style="text-align: center;">
					<i style="color: #000000;" class="fa fa-spinner"></i>
				</td>
			</tr>
		</tbody>
        <tbody *ngIf="currentState==='loaded'">
			<tr *ngIf="!users.length">
				<td [attr.colspan]="propsToReturn.length" style="text-align: center;">
					{{ "general.no_results" | translate }}
				</td>
			</tr>
			<tr *ngFor="let user of users" [routerLink]="'/user/'+user.id+'/edit'">
				<td *ngFor="let value of user.values; let i = index">
					<span *ngIf="i === 0 && user.userVerified">
						<i style="color: #00CF9E;" class="fa fa-shield"></i>
					</span>
					<span *ngIf="i === 0 && !user.userVerified">
						<i style="color: #CBD2D5;" class="fa fa-shield"></i>
					</span>
					{{value}}
				</td>
				<td (click)="deleteUser(user, userDeletingConfirmModal, $event)">X</td>
			</tr>
			<!-- </template> -->
		</tbody>
		<tbody *ngIf="currentState==='initial'">
			<tr>
				<td [attr.colspan]="propsToReturn.length" style="text-align: center;">
					{{ 'users.list_initial_info' | translate:{minCharactersToStartSearch: minCharactersToStartSearch} }}
				</td>
			</tr>
		</tbody>
	</table>
</large-page-layout>

<div bsModal #bindToCASModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="bindToCASModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">{{'users.bind_to_cas' | translate}}</h4>
      </div>
      <div class="modal-body">
		<div class="f-row">
			<label-input-pair label="{{ 'users.loginable_property' | translate }}" [(model)]="loginableUserProperty"></label-input-pair>
		</div>
        <div class="f-row">
          <button class="btn btn-w-md btn-line-info" (click)="bindToCASModal.hide()"><i class="fa fa-undo"></i>
            {{'general.cancel' | translate}}
          </button>
          &nbsp;
          <button class="btn btn-w-md btn-line-success" (click)="bindToCAS($event, bindToCASModal)"><i
                  class="fa fa-plus"></i> {{'general.add' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div bsModal #userDeletingConfirmModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
	 aria-hidden="true">
	<div class="modal-dialog modal-sm">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="userDeletingConfirmModal.hide()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<h4 class="modal-title">{{'users.deleting-confirm' | translate}}</h4>
			</div>
			<div class="modal-body">
				<table *ngIf="selectedUser">
					<tbody>
					<tr *ngFor="let prop of propsToReturn; let i = index">
						<td>{{ prop.label }}</td>
						<td>{{ selectedUser.values[i]}}</td>
					</tr>
					</tbody>
				</table>
				<div class="f-row">
					<button class="btn btn-w-md btn-line-info" (click)="userDeletingConfirmModal.hide()"><i class="fa fa-undo"></i>
						{{'general.cancel' | translate}}
					</button>
					&nbsp;
					<button class="btn btn-w-md btn-line-danger" (click)="confirmDelete(selectedUser, userDeletingConfirmModal)"><i
							class="fa fa-plus"></i> {{'general.delete' | translate}}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
