<div class="page-signin" style="display:flex;justify-content: center;align-items: center;min-height:100%;padding-top: 1rem">
    <div class="signin-body">
        <div class="container">
            <div class="form-container">
                <h3 style="margin-top:0;margin-bottom: 1rem" class="un-heading u-centered">{{ 'SIGN UP FOR ACCOUNT' | translate
                    }}</h3>
                <form ngNativeValidate (ngSubmit)="register();" name="loginForm">
                    <strong>{{errorMessage}}</strong>
                    <fieldset>

                        <label-input-pair label="users.username"
                                          large=true
                                          required
                                          icon="<i class='fa fa-user'></i>"
                                          [(model)]="user.username"></label-input-pair>

                        <label-input-pair label="users.phone"
                                          large=true
                                          required
                                          icon="#"
                                          [(model)]="user.phone"></label-input-pair>


                        <label-input-pair label="users.address"
                                          large=true
                                          required
                                          icon="<i class='fa fa-institution'></i>"
                                          [(model)]="user.address"></label-input-pair>

                        <label-input-pair type="email" label="users.email"
                                          large=true
                                          required
                                          icon="@"
                                          [(model)]="user.email"></label-input-pair>

                        <label-input-pair type="password" label="users.password"
                                          large=true
                                          required
                                          (change)="updateErrorMessage()"
                                          icon="<i class='fa fa-lock'></i>"
                                          [(model)]="user.password"></label-input-pair>

                        <label-input-pair type="password" label="users.repeat_password"
                                          large=true
                                          required
                                          (change)="updateErrorMessage()"
                                          [invalid]=showPasswordDoNotEqualMessage
                                          icon="<i class='fa fa-lock'></i>"
                                          [(model)]="passwordRepeat"></label-input-pair>
                        <div style="margin-bottom: 0.6rem" class="text-danger" *ngIf="showPasswordDoNotEqualMessage" >{{ 'Passwords do not match' | translate }}</div>
                        <label-input-pair label="users.firstname"
                                          large=true
                                          required
                                          icon="<i class='fa fa-font'></i>"
                                          [(model)]="user.firstName"></label-input-pair>

                        <label-input-pair label="users.lastname"
                                          large=true
                                          required
                                          icon="<i class='fa fa-font'></i>"
                                          [(model)]="user.lastName"></label-input-pair>

                        <label-input-pair label="NIT"
                                          large=true
                                          helpMessage="Format should be: 9999-999999-999-9"
                                          icon="#"
                                          pattern="[0-9]{4}-[0-9]{6}-[0-9]{3}-[0-9]{1}"
                                          placeholder="9999-999999-999-9"
                                          [(model)]="user.NIT"></label-input-pair>

                        <label-input-pair label="DUI"
                                          large=true
                                          helpMessage="Format should be: 99999999-9"
                                          icon="#"
                                          pattern="[0-9]{8}-[0-9]{1}"
                                          placeholder="99999999-9"
                                          [(model)]="user.DUI"></label-input-pair>

                        <div class="form-group">
                            <p class="error text-danger" *ngIf="error">{{error}}</p>
                            <p class="error text-danger" *ngFor="let error of errors">{{error}}</p>
                        </div>
                        <div>
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary btn-lg btn-block" style="text-transform: uppercase">{{ 'Sign up' |
                                    translate }}
                                </button>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
</div>
