<div style="float: right; padding-right: 4rem; padding-top: 2rem; font-size: 20px">
    <div style="display: inline" *ngFor="let lang of translate.getLangs(); let index = index;" >
        <a class="lang-link {{lang === translate.currentLang ? 'lang-link--active' : ''}}" href="#" (click)="translate.use(lang); false;">{{ lang | translate }}</a>
        <span *ngIf="translate.getLangs().length - 1 > index"> | </span>
    </div>
</div>
<login-page-layout>
    <div class="form-container">
        <h3 style="font-weight: 400" class="text-danger text-center">{{error | translate}}</h3>
        <h3 style="margin-bottom: 0.5rem" class="un-heading u-centered">{{ 'Sign in to Central Authentication Service' | translate
            }}</h3>
            <fieldset>
                <div>
                    <div class="form-group">
                        <a href="{{authUrl}}cas/spa.html#/?response_type=code&client_id={{OAuthClientId}}&state={{randomHash}}&scope=any&redirect_uri={{OAuthRedirectUrl}}&lang={{translate.currentLang}}" type="submit" class="btn btn-primary btn-lg btn-block">{{ 'Sign in or sign up for account' |
                            translate }}
                        </a>
                    </div>
                </div>
            </fieldset>

        <br>
        <form ngNativeValidate [ngClass]="{'visibility-hidden': !showRestoreForm }"
              (ngSubmit)="requestNewPassword()"
              name="loginForm">
            <div class="form-group">
                <div class="input-group input-group-lg">
                            <span class="input-group-addon">
                                <span class="fa fa-mail-reply"></span>
                            </span>
                    <input required type="email" name="emailToRestore" [(ngModel)]="emailToRestore"
                           class="form-control"
                           placeholder="{{ 'Your E-Mail' | translate }}" required>
                </div>
            </div>
            <button class="btn btn-primary btn-lg btn-block" type="submit">{{ 'Request a new password' |
                translate }}
            </button>
        </form>
    </div>
</login-page-layout>