<form-page title="{{'users.user' | translate}} {{user.username}}"
           [errors]="errors"
           [successes]="successes"
           icon="fa-edit"
           *ngIf="user"
           [save]="save.bind(this)">
    <h2>{{user.username}}</h2>

    <label-input-pair *ngFor="let userProp of user.properties" label="{{ userProp.label | translate }}"
                      disabled
                      [(model)]="userProp.value"></label-input-pair>
    
    <div class="form-group">
        <strong>{{'role.name' | translate}}</strong>
        <div class="checkbox" *ngFor="let role of roles">
            <label>
                <input (change)="$event.target.checked? user.roles.push(role) : user.roles.splice(user.roles.indexOf(role),1)"
                       [attr.checked]="user.roles && user.roles.indexOf(role)>-1 ? true : null"
                       type="checkbox"
                       name="role"
                       value=role> {{role}}<br>
            </label>
        </div>
    </div>
    <h3>{{'Institutions' | translate}}</h3>
    <div *ngIf="allInstitutions.length" class="form-group">
        <div *ngFor="let institution of allInstitutions">
            <div *ngIf="institutionShouldBeShownInList(institution.id)">
                <h4>{{institution.name}}</h4>
                <div *ngFor="let roleType of roleTypes" style="margin-left: 2rem">
                    <div class="role-row" *ngIf="showInstitutionLabel(institution.id, roleType.id)">
                        <i class="fa fa-trash-o user-edit-icons" aria-hidden="true"
                           (click)="removeRoleFromInstitution(institution, roleType)"></i>
                        <span class="role-text">{{roleType.name | translate}}</span>
                    </div>
                </div>
                <div *ngFor="let institutionUnit of institution.units">
                    <div *ngIf="institutionUnitShouldBeShownInTheList(institutionUnit.id)" style="margin-left: 2rem">
                        <h5>{{institutionUnit.name}}</h5>
                        <div *ngFor="let roleType of roleTypes">
                            <div class="role-row" style="margin-left: 2rem" *ngIf="showInstitutionUnitLabel(institutionUnit.id, roleType.id)">
                                <i class="fa fa-trash-o user-edit-icons" aria-hidden="true"
                                   (click)="removeRoleFromInstitutionUnit(institutionUnit, roleType)"></i>
                                <span class="role-text">{{roleType.name | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	</div>
	<p *ngIf="!allInstitutions.length">{{'There are no institutions defined at the moment' | translate}}</p>

    <h4 *ngIf="allInstitutions.length">{{'Add new institution user rights' | translate}}</h4>
    <div *ngIf="allInstitutions.length" class="row">
        <div class="col-md-12">
            <form class="form-horizontal" (submit)="addInstitutionOrInstitutionUnitForInstitutionOnly()">
                <div class="form-group">
                    <label for="selectedInstitutionId" class="col-sm-2 control-label">{{'Institution' | translate}}</label>
                    <div class="col-sm-10">
                        <select id="selectedInstitutionIdForInstitutionOnly" class="form-control"
                                [(ngModel)]="selectedInstitutionIdForInstitutionOnly" name="selectedInstitutionId">
                            <option *ngFor="let option of allInstitutions" [ngValue]="option.id">
                                {{option.name | translate}}
                            </option>
                        </select>
                    </div>
                </div>
                <div *ngIf="selectedInstitutionIdForInstitutionOnly" class="form-group">
                    <label for="selectedRole" class="col-sm-2 control-label">{{'Role' | translate}}</label>
                    <div class="col-sm-10">
                        <select id="selectedRoleForInstitutionOnly" class="form-control"
                                [(ngModel)]="selectedRoleForInstitutionOnly"
                                name="selectedRoleForInstitutionOnly">
                            <option *ngFor="let option of roleTypes" [ngValue]="option.id">
                                {{option.name | translate}}
                            </option>
                        </select>
                    </div>
                </div>
                <div *ngIf="selectedInstitutionIdForInstitutionOnly && selectedRoleForInstitutionOnly" class="form-group">
                    <div class="col-sm-12">
                        <input class="btn btn-line-success pull-right" type="submit" value="{{'Add' | translate}}">
                    </div>
                </div>
            </form>
        </div>
    </div>

    <h4 *ngIf="allInstitutions.length">{{'Add new institution user rights for unit' | translate}}</h4>
    <div *ngIf="allInstitutions.length" class="row">
        <div class="col-md-12">
            <form class="form-horizontal" (submit)="addInstitutionOrInstitutionUnit()">
                <div class="form-group">
                    <label for="selectedInstitutionId" class="col-sm-2 control-label">{{'Institution' | translate}}</label>
                    <div class="col-sm-10">
                        <select id="selectedInstitutionId" class="form-control" (change)="updateSelectedInstitutionInstitutionUnits()"
                                [(ngModel)]="selectedInstitutionId" name="selectedInstitutionId">
                            <option *ngFor="let option of allInstitutions" [ngValue]="option.id">
                                {{option.name | translate}}
                            </option>
                        </select>
                    </div>
                </div>
                <div *ngIf="selectedInstitutionId && selectedInstitutionInstitutionUnits.length > 0" class="form-group">
                    <label for="selectedInstitutionUnitId" class="col-sm-2 control-label">{{'Unit' | translate}}</label>
                    <div class="col-sm-10">
                        <select class="form-control" [(ngModel)]="selectedInstitutionUnitId"
                                (change)="updateSelectedRoles()"
                                id="selectedInstitutionUnitId"
                                name="selectedInstitutionUnitId">
                            <option *ngFor="let option of selectedInstitutionInstitutionUnits"
                                    [ngValue]="option.id">
                                {{option.name | translate}}
                            </option>
                        </select>
                    </div>
                </div>
                <div *ngIf="selectedInstitutionId" class="form-group">
                    <label for="selectedRole" class="col-sm-2 control-label">{{'Role' | translate}}</label>
                    <div class="col-sm-10">
                        <select id="selectedRole" class="form-control" [(ngModel)]="selectedRole" name="selectedRole">
                            <option *ngFor="let option of roleTypes" [ngValue]="option.id">
                                {{option.name | translate}}
                            </option>
                        </select>
                    </div>
                </div>
                <div *ngIf="selectedInstitutionId && selectedRole" class="form-group">
                    <div class="col-sm-12">
                        <input class="btn btn-line-success pull-right" type="submit" value="{{'Add' | translate}}">
                    </div>
                </div>
            </form>
        </div>
    </div>
</form-page>


