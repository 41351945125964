<div class="form-group">
    <label class="label-for-pair {{large ? 'label-for-pair--large' : '' }}" for="{{label}}">
        <span style="font-weight:400" class="text-danger">{{required ? '* ' : '' }}</span>
        {{label | translate}} <span [innerHTML]="additionalInfo"></span>
    </label>
    <div style="width:100%" class="input-group {{large ? 'input-group-lg ' : '' }}">
        <span *ngIf="icon" class="input-group-addon" [innerHTML]="icon"></span>
        <input [attr.type]="type ? type : null"
               [(ngModel)]="model"
               (ngModelChange)="onChanges($event)"
               [attr.required]="required ? true : null"
               [attr.disabled]="disabled ? true : null"
               class="form-control {{invalid ? 'input--errored' : '' }}"
               name="{{label}}"
               id="{{label}}"
               pattern="{{pattern}}"
               placeholder="{{placeholder ? placeholder: label | translate}}">
    </div>
    {{helpMessage | translate}}
</div>